<template>
  <div class="p-3">
    <div class="alert alert-danger" role="alert" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <b-table
      ref="table"
      id="news-item-table"
      :fields="fields"
      :items="loadTableData"
      :current-page="currentPage"
      :per-page="perPage"
      primary-key="id"
      thead-class="d-none"
      outlined
      responsive
      show-empty
      small
      :empty-text="$t('shared.emptyData')"
      style="min-height: 120px"
    >
      <template #cell(content)="data">
        <NewsListItem :item="data.item" @click="newsItemClicked" />
      </template>
    </b-table>
    <b-pagination
      v-if="totalRows > perPage"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="news-item-table"
    ></b-pagination>
  </div>
</template>

<script>
import httpService from "@/services/HttpService";
import { Browser } from "@capacitor/browser";
import NewsListItem from "@/components/NewsListItem";
export default {
  components: {
    NewsListItem,
  },
  data() {
    return {
      errorMessage: null,
      fields: ["content"],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      items: [],
    };
  },
  methods: {
    loadTableData(ctx, callback) {
      const vm = this;
      httpService
        .get(`/api/places/${this.$route.params.id}/news-items`, {
          params: {
            page: ctx.currentPage,
            per_page: ctx.perPage,
          },
          handleRequestStart: () => (this.errorMessage = null),
          handleRequestError: /* istanbul ignore next */ (
            _error,
            message
          ) => /* istanbul ignore next */ {
            this.errorMessage = message;
            callback([]);
          },
        })
        .then((response) => {
          vm.totalRows = response.data.total;
          callback(response.data.items);
        });
      return null;
    },
    /* istanbul ignore next */
    newsItemClicked(item) {
      Browser.open({ url: item.link_url });
    },
  },
};
</script>
